<template>
  <v-btn
      :icon="true"
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="warning"
      @click="init()"
  >
    <v-icon>
      mdi-pencil
    </v-icon>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        max-width="500"
    >
      <v-card>
        <v-card-title>
          Upraviť záznam

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>
            Pre upravenie záznamu je potrebné vyplniť všetky povinné polia.
          </p>

          <SuggestYears
              v-model="RecordForm.Year"
              :error-messages="errors.Year"
              :outlined="true"
              :dense="true"
              label="Rok"
              :clearable="true"
          />

          <SuggestCountries
              v-model="RecordForm.Country"
              :error-messages="errors.Country"
              :outlined="true"
              :dense="true"
              label="Krajina"
              :clearable="true"
          />

          <SuggestDistricts
              v-model="RecordForm.District"
              :error-messages="errors.District"
              :outlined="true"
              :dense="true"
              label="Okres"
              :clearable="true"
          />

          <SuggestCities
              v-model="RecordForm.City"
              :error-messages="errors.City"
              :outlined="true"
              :dense="true"
              label="Mesto"
              :clearable="true"
          />

          <v-text-field
              v-model="RecordForm.street"
              :error-messages="errors.street"
              :outlined="true"
              :dense="true"
              label="Ulica"
          />

          <v-text-field
              v-model="RecordForm.number"
              :error-messages="errors.number"
              :outlined="true"
              :dense="true"
              label="Číslo"
          />

          <v-text-field
              v-model="RecordForm.box"
              :error-messages="errors.box"
              :outlined="true"
              :dense="true"
              label="Krabica"
          />

          <v-text-field
              v-model="RecordForm.sheet"
              :error-messages="errors.sheet"
              :outlined="true"
              :dense="true"
              label="Hárok"
          />

          <v-text-field
              v-model="RecordForm.code"
              :error-messages="errors.code"
              :outlined="true"
              :dense="true"
              label="Kód"
          />

          <v-text-field
              v-model="RecordForm.disc"
              :error-messages="errors.disc"
              :outlined="true"
              :dense="true"
              label="Disk"
          />

          <v-text-field
              v-model="RecordForm.stamp"
              :error-messages="errors.stamp"
              :outlined="true"
              :dense="true"
              label="Pečiatka"
          />

          <v-text-field
              v-model="RecordForm.object"
              :error-messages="errors.object"
              :outlined="true"
              :dense="true"
              label="Objekt"
          />

          <v-text-field
              v-model="RecordForm.families"
              :error-messages="errors.families"
              :outlined="true"
              :dense="true"
              label="Priezviská"
          />

          <v-select
              v-model="RecordForm.type"
              :error-messages="errors.type"
              :outlined="true"
              :dense="true"
              label="Typ"
              :items="$store.getters['register/getRecordType']"
              :clearable="true"
          />

          <v-btn
              :rounded="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="warning"
              @click="submit()"
          >
            <v-icon>
              mdi-pencil
            </v-icon>

            Upraviť
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Records from '@/services/records'

  import SuggestYears from '@/components/Years/Suggest'
  import SuggestCountries from '@/components/Countries/Suggest'
  import SuggestDistricts from '@/components/Districts/Suggest'
  import SuggestCities from '@/components/Cities/Suggest'

  export default {
    name: 'Update',
    props: [
      'id'
    ],
    components: {
      SuggestYears,
      SuggestCountries,
      SuggestDistricts,
      SuggestCities
    },
    data() {
      return {
        loading: false,
        dialog: false,
        Record: {},
        RecordForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = false

        this.loading = true

        Records
          .read({
            id: this.id
          })
          .then((response) => {
            this.dialog = true

            this.Record - response.data.data.Record

            this.RecordForm = {
              Year: (
                response.data.data.Record.Year
                  ? response.data.data.Record.Year.id
                  : ''
              ),
              Country: (
                response.data.data.Record.Country
                  ? response.data.data.Record.Country.id
                  : ''
              ),
              District: (
                response.data.data.Record.District
                  ? response.data.data.Record.District.id
                  : ''
              ),
              City: (
                response.data.data.Record.City
                  ? response.data.data.Record.City.id
                  : ''
              ),
              street: response.data.data.Record.street,
              number: response.data.data.Record.number,
              box: response.data.data.Record.box,
              sheet: response.data.data.Record.sheet,
              code: response.data.data.Record.code,
              disc: response.data.data.Record.disc,
              stamp: response.data.data.Record.stamp,
              object: response.data.data.Record.object,
              families: response.data.data.Record.families,
              type: response.data.data.Record.type
            }

            this.errors = {}
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.dialog = true

            this.loading = false
          })
      },
      submit() {
        this.loading = true

        Records
          .update({
            id: this.id,
            data: this.RecordForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            this.$emit('init')
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 401:
              case 404:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                this.closeDialog()

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
