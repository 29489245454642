import Vue from 'vue'

class Years {
  async suggest() {
    return await Vue.axios
      .get('admin/years/suggest')
  }
}

const years = new Years()

export default years
