const master = [
  {
    to: '/home',
    title: 'Nástenka',
    icon: 'mdi-home'
  },
  {
    to: '/users',
    title: 'Užívatelia',
    icon: 'mdi-account'
  },
  {
    to: '/records',
    title: 'Záznamy',
    icon: 'mdi-file'
  }
]

const worker = [
  {
    to: '/home',
    title: 'Nástenka',
    icon: 'mdi-home'
  }
]

const items = {
  master,
  worker
}

const menu = {
  items
}

export default menu
