const state = {
  drawer: true,
  breadcrumbs: [],
  snackbar: false,
  message: {}
}

const mutations = {
  SET_DRAWER(state, drawer) {
    state.drawer = drawer
  },
  SET_BREADCRUMBS(state, breadcrumbs) {
    state.breadcrumbs = breadcrumbs
  },
  SET_SNACKBAR(state, snackbar) {
    state.snackbar = snackbar
  },
  SET_MESSAGE(state, message) {
    state.message = message
  }
}

const actions = {
  toggleDrawer({commit, state}) {
    commit('SET_DRAWER', !state.drawer)
  },
  setBreadcrumbs({commit}, {breadcrumbs}) {
    commit('SET_BREADCRUMBS', breadcrumbs)
  },
  addBreadcrumbs({commit, state}, {breadcrumbs}) {
    commit('SET_BREADCRUMBS', [
      ...state.breadcrumbs,
      ...breadcrumbs
    ])
  },
  openSnackbar({commit}, {message}) {
    commit('SET_SNACKBAR', true)

    commit('SET_MESSAGE', message)
  },
  closeSnackbar({commit}) {
    commit('SET_SNACKBAR', false)

    commit('SET_MESSAGE', {})
  }
}

const getters = {}

const layout = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

export default layout
