import Vue from 'vue'

class Register {
  async index() {
    return await Vue.axios
      .get('admin/register')
  }
}

const register = new Register()

export default register
