import Vue from 'vue'

import Vuetify from 'vuetify'

Vue.use(Vuetify)

const options = {}

const vuetify = new Vuetify(options)

export default vuetify
