import Vue from 'vue'

class Districts {
  async suggest() {
    return await Vue.axios
      .get('admin/districts/suggest')
  }
}

const districts = new Districts()

export default districts
