<template>
  <v-btn
      :icon="true"
      :outlined="true"
      :loading="loading"
      :disabled="loading"
      color="primary"
      :ripple="false"
      @click="init"
  >
    <v-icon>
      mdi-login
    </v-icon>

    <v-dialog
        v-model="dialog"
        :persistent="true"
        max-width="500"
    >
      <v-card>
        <v-card-title>
          Prihlásiť sa do účtu

          <v-spacer/>

          <v-btn
              :icon="true"
              color="default"
              @click="closeDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <p>
            Pre prihlásenie sa do účtu sa je potrebné vyplniť všetky povinné polia.
          </p>

          <v-text-field
              v-model="UserForm.username"
              :error-messages="errors.username"
              :outlined="true"
              :dense="true"
              label="Užívateľské meno"
          />

          <v-text-field
              v-model="UserForm.password"
              :error-messages="errors.password"
              :outlined="true"
              :dense="true"
              label="Heslo"
              type="password"
          />

          <v-btn
              :rounded="true"
              :outlined="true"
              :loading="loading"
              :disabled="loading"
              color="primary"
              @click="submit"
          >
            <v-icon>
              mdi-login
            </v-icon>

            Prihlásiť sa
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-btn>
</template>

<script>
  import Auth from '@/services/auth'

  export default {
    name: 'Login',
    data() {
      return {
        loading: false,
        dialog: false,
        UserForm: {},
        errors: {}
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      closeDialog() {
        this.dialog = false
      },
      loadData() {
        this.dialog = true

        this.UserForm = {
          username: '',
          password: ''
        }

        this.errors = {}
      },
      submit() {
        this.loading = true

        Auth
          .login({
            data: this.UserForm
          })
          .then((response) => {
            this.$store.dispatch('layout/openSnackbar', {
              message: response.data.message
            })

            this.closeDialog()

            window.location.reload()
          })
          .catch((error) => {
            switch (error.response.status) {
              case 400:
                this.errors = error.response.data.errors

                break

              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
