import Vue from 'vue'

class Countries {
  async suggest() {
    return await Vue.axios
      .get('admin/countries/suggest')
  }
}

const countries = new Countries()

export default countries
