import Vue from 'vue'

import VueRouter from 'vue-router'

import Session from '@/middleware/session'

import Home from '@/views/Home'
import Error from '@/views/Error'
import Users from '@/views/Users'
import Records from '@/views/Records'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '*',
    redirect: '/error'
  },
  {
    path: '/home',
    component: Home,
    meta: {
      type: 'none',
    },
    beforeEnter: Session
  },
  {
    path: '/error',
    component: Error,
    meta: {
      type: 'none',
    },
    beforeEnter: Session
  },
  {
    path: '/users',
    component: Users,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  },
  {
    path: '/records',
    component: Records,
    meta: {
      type: 'logged',
      auth: {
        isGranted: true,
        User: {
          roles: [
            'ROLE_MASTER'
          ]
        }
      }
    },
    beforeEnter: Session
  }
]

const options = {
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: routes
}

const router = new VueRouter(options)

export default router
