import Vue from 'vue'

class Auth {
  async login({data}) {
    return await Vue.axios
      .post('admin/auth/login', data)
  }

  async session() {
    return await Vue.axios
      .get('admin/auth/session')
  }

  async logout() {
    return await Vue.axios
      .delete('admin/auth/logout')
  }
}

const auth = new Auth()

export default auth
