<template>
  <v-autocomplete
      :items="items"
      item-value="id"
      item-text="title"
      :loading="loading"
      :disabled="loading"
      v-bind="$attrs"
      v-on="$listeners"
  >
    <template
        v-slot:append-outer
    >
      <v-btn
          :small="true"
          :icon="true"
          :outlined="true"
          :loading="loading"
          :disabled="loading"
          @click="loadData"
      >
        <v-icon>
          mdi-refresh
        </v-icon>
      </v-btn>
    </template>
  </v-autocomplete>
</template>

<script>
  import Countries from '@/services/countries'

  export default {
    name: 'Suggest',
    props: [
      'support'
    ],
    data() {
      return {
        loading: false,
        countries: []
      }
    },
    mounted() {
      this.init()
    },
    computed: {
      items() {
        return (
          typeof this.support === 'function'
            ? this.support(this.countries)
            : this.countries
        )
      }
    },
    methods: {
      init() {
        this.loadData()
      },
      loadData() {
        this.loading = true

        Countries
          .suggest()
          .then((response) => {
            this.countries = response.data.data.countries
          })
          .catch((error) => {
            switch (error.response.status) {
              case 401:
              case 500:
                this.$store.dispatch('layout/openSnackbar', {
                  message: error.response.data.message
                })

                break
            }
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
</script>
