<template>
  <v-card>
    <v-card-title>
      Chyba
    </v-card-title>

    <v-card-text>

    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'Error',
    metaInfo() {
      return {
        title: 'Chyba'
      }
    }
  }
</script>
