import Vue from 'vue'

import Vuex from 'vuex'

import layout from '@/plugins/store/modules/layout'
import register from '@/plugins/store/modules/register'
import auth from '@/plugins/store/modules/auth'

Vue.use(Vuex)

const state = {}

const mutations = {}

const actions = {}

const getters = {}

const modules = {
  layout,
  register,
  auth
}

const options = {
  state,
  mutations,
  actions,
  getters,
  modules
}

const store = new Vuex.Store(options)

export default store
