<template>
  <v-row>
    <v-col
        cols="12"
    >
      ...
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'Home',
    metaInfo() {
      return {
        title: 'Domov'
      }
    }
  }
</script>
