import Vue from 'vue'

import VueAxios from 'vue-axios'

import Axios from 'axios'

Axios.defaults.baseURL = process.env.VUE_APP_AXIOS_URL

Axios.defaults.withCredentials = true // TODO

Vue.use(VueAxios, Axios)
