import Vue from 'vue'

import Qs from 'qs'

class Records {
  async index({params}) {
    return await Vue.axios
      .get('admin/records', {
        params,
        paramsSerializer: (params) => {
          return Qs.stringify(params)
        }
      })
  }

  async read({id}) {
    return await Vue.axios
      .get('admin/records/' + id)
  }

  async update({id, data}) {
    return await Vue.axios
      .put('admin/records/' + id, data)
  }

  async delete({id}) {
    return await Vue.axios
      .delete('admin/records/' + id)
  }
}

const records = new Records()

export default records
